import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/z97Ha8PBhIs"
    bibleGroupSrc="https://www.docdroid.net/hmP6oHt/bible-group-homework-8-16-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/30877118"
  >
    <SEO title="Joel - Now What?" />
  </Layout>
)

export default SermonPost
